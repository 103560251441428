(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("microkernel"), require("core-polyfill-loading"), require("core-utils"));
	else if(typeof define === 'function' && define.amd)
		define("modelfinder", ["microkernel", "core-polyfill-loading", "core-utils"], factory);
	else if(typeof exports === 'object')
		exports["modelfinder"] = factory(require("microkernel"), require("core-polyfill-loading"), require("core-utils"));
	else
		root["modelfinder"] = factory(root["microkernel"], root["core-polyfill-loading"], root["core-utils"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__729__, __WEBPACK_EXTERNAL_MODULE__80__, __WEBPACK_EXTERNAL_MODULE__139__) {
return 