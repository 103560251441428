export default class Viewport {

	static get DESKTOP() {
		return 'desktop';
	}

	static get isMobileSmall() {
		return (window.innerWidth <= 374);
	}

	static get isMobile() {
		return (window.innerWidth > 374
			&& window.innerWidth <= 567);
	}

	static get isTablet() {
		return (window.innerWidth > 567
			&& window.innerWidth < 866);
	}

	static get isTabletLarge() {
		return (window.innerWidth >= 866
			&& window.innerWidth < 1024);
	}

	static get isDesktop() {
		return (window.innerWidth >= 1024);
	}

	static get currentViewport() {
		if (Viewport.isMobileSmall) {
			return 'mobile-small';
		}
		else if (Viewport.isMobile) {
			return 'mobile';
		}
		else if (Viewport.isTablet) {
			return 'tablet';
		}
		else if (Viewport.isTabletLarge) {
			return 'tablet-large';
		}
		else {
			return Viewport.DESKTOP;
		}
	}
}

