import Viewport from './viewport';

export default class CarlinegroupNavigation {

	constructor(carlineGroup_) {
		this._initializeElements(carlineGroup_);
		this._bindEventHandler();

		this._setInitialValues();
		this._addEvents();
		this._updateCarouselState(0);
	}

	get tileWidth() {
		return this.carModelsContainer.querySelector('.audi-modelfinder__car-model').offsetWidth;
	}

	/**
	 * _initializeElements
	 * @param {HTMLElement} carlineGroup_ - element that holds all carousel items
	 * @returns {void}
	 */
	_initializeElements(carlineGroup_) {
		this.carlineGroup = carlineGroup_;
		this.carModelsContainer = carlineGroup_.querySelector('.audi-modelfinder__car-models');
		this.nextButtonControl = carlineGroup_.querySelector('.audi-modelfinder__scroll-container-next-button');
		this.previousButtonControl = carlineGroup_.querySelector('.audi-modelfinder__scroll-container-previous-button');
		this.scrollContainer = carlineGroup_.querySelector('.audi-modelfinder__scroll-container');
	}

	/**
	 * _bindEventHandler
	 * @returns {void}
	 */
	_bindEventHandler() {
		this._controlsClickHandler = this._controlsClickHandler.bind(this);
		this._scrollHandler = this._scrollHandler.bind(this);
	}

	/**
	 * _setInitialValues
	 * @returns {void}
	 */
	_setInitialValues() {
		this.amountOfTiles = this.carModelsContainer.querySelectorAll('.audi-modelfinder__car-model').length;
		this.scrollPosition = 0;
		this.isStartPosition = this.scrollPosition === 0;
		this.isEndPosition = this.carModelsContainer.lastElementChild.dataset.isActiveCar === 'true';
		this.snapThreshold = 20;
	}

	/**
	 * _addEvents
	 * @returns {void}
	 */
	_addEvents() {
		const delay = 100;

		if (!Viewport.isDesktop) {
			let scrollTaskId = null;

			this.nextButtonControl.addEventListener('click', this._controlsClickHandler);
			this.previousButtonControl.addEventListener('click', this._controlsClickHandler);
			this.scrollContainer.addEventListener('scroll', event_ => {
				if (scrollTaskId !== null) {
					clearTimeout(scrollTaskId);
				}

				scrollTaskId = setTimeout(() => {
					scrollTaskId = null;
					this._scrollHandler(event_);
				}, delay);
			});
		}
	}

	/**
	 * _controlsClickHandler
	 * @param {Event} event_ Click Event fired by click on arrow button
	 * @returns {void}
	 */
	_controlsClickHandler(event_) {
		if (event_.target.dataset.disabled === 'true') {
			return;
		}

		const tileGap = 12;
		const arrow = event_.target;
		const arrowDirection = (arrow === this.nextButtonControl) ? 1 : -1;

		let scrollDestination = this.scrollPosition + ((this.tileWidth + tileGap) * arrowDirection);

		this.scrollContainer.scrollTo({
			top: 0,
			left: scrollDestination,
			behavior: 'smooth'
		});
	}

	_scrollHandler(event_) {
		this.scrollPosition = event_.target.scrollLeft;

		this._updateCarouselState();
	}

	/**
	 * _updateCarouselState - update the State of the controls based on the position of active tiles
	 * @returns {void}
	 */
	_updateCarouselState() {
		const currentScrollPosition = Math.abs(this.scrollPosition);
		const maximalPossibleTranslation = Viewport.isTabletLarge ?
			this.tileWidth + this.tileWidth / 2 * (this.amountOfTiles - 1) :
			this.tileWidth * (this.amountOfTiles - 1);
		const isStartPosition = this.scrollPosition <= this.snapThreshold;
		const isEndPosition = Viewport.isTabletLarge ?
			currentScrollPosition + this.tileWidth >= maximalPossibleTranslation - this.snapThreshold :
			currentScrollPosition >= maximalPossibleTranslation - this.snapThreshold;

		this.previousButtonControl.dataset.disabled = isStartPosition;
		this.previousButtonControl.style.backgroundColor = 'transparent'; // fix for IE11 to trigger repaint
		this.nextButtonControl.dataset.disabled = isEndPosition;
		this.nextButtonControl.style.backgroundColor = 'transparent'; // fix for IE11 to trigger repaint
	}
}
