import Viewport from '../modelfinder-carlinegroup/viewport';

export default class ModelfinderCarModel extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this._resizeHandler = this._resizeHandler.bind(this);
		this._intersectionCallback = this._intersectionCallback.bind(this);
		this.carlineImage = this.querySelector('.audi-modelfinder__car-model-image-container');
		this.intersectionThreshold = 0.90;

		window.addEventListener('resize', this._resizeHandler);
		this._resizeHandler();
	}

	disconnectedCallback() {
		this._removeIntersectionObserver();
		window.removeEventListener('resize', this._resizeHandler);
	}

	_resizeHandler() {
		if (!Viewport.isDesktop) {
			this._addIntersectionObserver();
		}
		else {
			this._removeIntersectionObserver();
		}
	}

	/**
	 * _intersectionCallback
	 *
	 * @param {Array} intersections_ collisions from Intersection Observer
	 * @returns {void}
	 */
	_intersectionCallback(intersections_) {
		intersections_.forEach(entry => {
			const carlineImage = entry.target;
			const tileFullyVisible =
				(entry.intersectionRatio >= this.intersectionThreshold
					|| entry.intersectionRect.width >= carlineImage.offsetWidth);

			this.dataset.isActiveCar = tileFullyVisible;

			// IE11 Workaround (doesnt repaint when dataset property change)
			// We touch the style here to signal Trident he needs to repaint
			this.style.backgroundColor = 'transparent';
		});
	}

	/**
	 * hooks an intersection observer on the carmodel if the model has an image
	 * @returns {void}
	 */
	_addIntersectionObserver() {
		if (this.carlineImage) {
			const intersectionOptions = {
				// rootMargin grows the viewport and allows us to fire the callback also
				// when the tile is not fully completed on the y axis. It is used on
				// y-axis scroll
				rootMargin: '500px 0px 500px 0px',
				threshold: [this.intersectionThreshold]
			};
			this.intersectionObserver = new IntersectionObserver(
				this._intersectionCallback,
				intersectionOptions
			);
			this.intersectionObserver.observe(this.carlineImage);
		}
	}

	_removeIntersectionObserver() {
		if (this.intersectionObserver) {
			this.intersectionObserver.unobserve(this.carlineImage);
			this.intersectionObserver.disconnect();
			delete this.intersectionObserver;
		}
	}
}

if (window.customElements.get('audi-modelfinder-car-model') === undefined) {
	customElements.define('audi-modelfinder-car-model', ModelfinderCarModel);
}
