import {stateRegistry} from 'microkernel';
import {imageLoadingPolyfill} from 'core-polyfill-loading';
import {polling} from 'core-utils';

export default class ModelFinder extends HTMLElement {
	constructor() {
		super();

		this._targetLocationSearch = '';
		this._requestFilterResults = this._requestFilterResults.bind(this);
		this._updateLocation = this._updateLocation.bind(this);
		this._processFilterResults = this._processFilterResults.bind(this);
		this._fadeOut = this._fadeOut.bind(this);
		this._exchangeContent = this._exchangeContent.bind(this);
		this._getRelevantModelfinderContainer = this._getRelevantModelfinderContainer.bind(this);
		this._fadeIn = this._fadeIn.bind(this);
		this.animationComplete = true;
		this._updateModelfinderStore = this._updateModelfinderStore.bind(this);
	}

	connectedCallback() {
		this._targetLocationSearch = window.location.search;
		imageLoadingPolyfill.registerCustomEvent('content:rendered');
		const modelfinderFilter = this.parentElement.querySelector('audi-modelfinder-filter');

		if (modelfinderFilter) {
			const formElement = modelfinderFilter.querySelector('.modelfinder-filter-j-form');

			if (formElement) {
				this.isModelfinderStoreInitialized = false;
				this.targetUrl = formElement.getAttribute('action');
				stateRegistry.subscribeToStore('dbadModelfinderStore', this._requestFilterResults);
			}
		}
	}

	/**
	 * request filter results for a given state
	 * @param {state} state_ - the current state of the modelfinder-store
	 * @returns {void}
	 */
	_requestFilterResults(state_) {
		if (this.isModelfinderStoreInitialized) {
			const queryString = encodeURI(this._calculateQueryString(state_.filter));

			if (queryString !== this._targetLocationSearch) {
				this._targetLocationSearch = queryString;
				this._fadeOut();
				this.animationComplete = false;
				this._fetchFilterResults(this.targetUrl, queryString);
				setTimeout(() => {
					this.animationComplete = true;
				}, 250);
			}
		}
		else {
			this.isModelfinderStoreInitialized = true;
		}
	}

	/**
	 * calculates a query string to a given filter object
	 * @param {Object} filter_ - the filter object
	 * @returns {string} - the query string
	 */
	_calculateQueryString(filter_) {
		let queryString = '';

		const filterStructure = this._aggregateFilterStructure(filter_);

		for (const [key, value] of Object.entries(filterStructure)) {
			queryString += '&' + key + '=' + value;
		}

		if (queryString.length > 0) {
			queryString = '?' + queryString.substr(1);
		}

		return queryString;
	}

	/**
	 * transform a filter Map structure which can be transformed to a query string
	 * note: the filters in the store are not aggregated, in order to be sorted correctly
	 * - for the query parameters we need them aggegated
	 * @param {Object} filter_ the filter object (in the store-specific form)
	 * @returns {Object} - an easier-processable, transformed object
	 */
	_aggregateFilterStructure(filter_) {
		let filterStructure = {};

		for (const [key, value] of Object.entries(filter_)) {
			const filterKey = ModelFinder.extractFilterKey(key);
			const currentFilterValue = filterStructure[filterKey];

			if (currentFilterValue && filterKey !== key) {
				filterStructure[filterKey] = currentFilterValue + ',' + value;
			}
			else {
				filterStructure[filterKey] = value;
			}
		}

		return filterStructure;
	}

	/**
	 * extract possibly key from a possibly annotated key
	 * @param {string} rawFilterKey_ - the raw key
	 * @returns {string} - key without annotation
	 */
	static extractFilterKey(rawFilterKey_) {
		let filterKey = rawFilterKey_;
		const posOfHyphen = rawFilterKey_.indexOf('-');

		if (posOfHyphen !== -1) {
			filterKey = rawFilterKey_.substr(0, posOfHyphen);
		}

		return filterKey;
	}

	/**
	 * fetch data, process response and pass it along
	 *
	 * @static
	 * @param {string} targetUrl_ the target url for the request
	 * @param {string} getParameters_ get parameters string
	 * @returns {Promise} - promise (only needed for easier testing)
	 */
	_fetchFilterResults(targetUrl_, getParameters_) {
		const fetchUrl = targetUrl_ + getParameters_;

		return fetch(fetchUrl)
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response;
			})
			.then((response) => response.text())
			.then((responseText) => {
				if (this._targetLocationSearch === getParameters_) {
					this._updateLocation(getParameters_);
					this._processFilterResults(responseText);
				}
			})
			.catch((err) => {
				// TODO: Display Error template
				this._processFilterResults(false);
				console.warn(err);
			});
	}

	/**
	 * update location with new get parameters
	 * @param {string} getParameters_ - the get parameters to set
	 * @returns {void}
	 */
	_updateLocation(getParameters_) {
		let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

		if (getParameters_ !== '') {
			newUrl += getParameters_;
		}

		window.history.pushState({path: newUrl}, '', newUrl);
	}

	/**
	 * process the fetched filter result HTML
	 *
	 * @param {string} htmlString_ - html string with filter results
	 * @returns {void}
	 */
	async _processFilterResults(htmlString_) {
		if (!htmlString_) {
			return;
		}

		polling
			.wait(() => this.animationComplete, 350)
			.then(() => {
				this._exchangeContent(htmlString_);
				this._fadeIn();
				this._updateModelfinderStore();
			})
			.catch(() => {
				this._exchangeContent(htmlString_);
				this._fadeIn();
				this._updateModelfinderStore();
			});
	}

	/**
	 * call data collection and pass through to storage update handler
	 * @returns {void}
	 */
	_updateModelfinderStore() {
		const resultsSelection = this.querySelectorAll('.audi-modelfinder__car-model');
		const selectionObject = ModelFinder.getModelsData(resultsSelection);

		stateRegistry.triggerAction('dbadModelfinderStore', 'updateModels', selectionObject);
	}

	/**
	 * call data collection and pass through to storage update handler
	 * @param {Object} resultsSelection_ Nodelist
	 * @returns {Object} - the Object data for the Store to update
	 */
	static getModelsData(resultsSelection_) {
		let selectionObject = {};

		[...resultsSelection_].forEach((model) => {
			const modelId = model.dataset.modelId;
			const modelName = model.dataset.modelName;
			const modelLink = model.querySelector('.audi-modelfinder__car-model-link').getAttribute('href');
			let modelOriginalImageURL = '';

			if (model.querySelector('.audi-modelfinder__car-model-image')
				&& model.querySelector('.audi-modelfinder__car-model-image').getAttribute('src') !== '') {
				const modelImageURL = model.querySelector('.audi-modelfinder__car-model-image').getAttribute('src');

				if (modelImageURL.indexOf('?') !== -1) {
					modelOriginalImageURL = modelImageURL.split('?')[0];
				}
			}

			selectionObject[modelId] = {
				modelName: modelName,
				modelLink: modelLink,
				modelImageURL: modelOriginalImageURL
			};
		});

		return selectionObject;
	}

	/**
	 * exchange the modelfinder content
	 * @param {string} htmlString_ - the new content to use
	 * @returns {void}
	 */
	_exchangeContent(htmlString_) {
		const containerToReplace = this._getRelevantModelfinderContainer(this);
		const containerParentNode = containerToReplace.parentNode;

		const temmporaryContainer = document.createElement('div');
		temmporaryContainer.innerHTML = htmlString_;

		const newModelfinderContainer = this._getRelevantModelfinderContainer(temmporaryContainer);
		newModelfinderContainer.classList.add('audi-modelfinder--faded-out');

		const initialHeight = containerParentNode.clientHeight;
		containerParentNode.style.minHeight = initialHeight;

		containerParentNode.removeChild(containerToReplace);
		containerParentNode.appendChild(newModelfinderContainer);

		containerParentNode.style.minHeight = 0;

		this._dispatchEventsAfterExchangeContent();
	}

	/**
	 * dispatches custom events, modelfinder::content-exchanged with the current result amount taken from the dom, content:rendered to inform the footnotes engine the DOM needs to be refreshed
	 * @returns {void}
	 */
	_dispatchEventsAfterExchangeContent() {
		const resultsAmountElement = this.querySelector('.modelfinder-j-results-amount');
		const amount = parseInt(resultsAmountElement && resultsAmountElement.dataset.resultsAmount || -1, 10);

		// events need to be dispatched also in case no amount has been retrieved in the markup
		// (because there is none on the modelfinder template)
		document.dispatchEvent(
			new CustomEvent('modelfinder::content-exchanged', {
				detail: {
					results: amount
				}
			})
		);
		document.dispatchEvent(
			new CustomEvent('content:rendered', {})
		);
	}

	/**
	 * The <audi-modelfinder> element can either contain markup with the initial view
	 * (in .audi-modelfinder__car-lines) or with search results (audi-modelfinder__filter-results)
	 * We usually do not know which of it is contained - but we can find out with this function...
	 * @param {DOMElement} context_ - the DOM in which the container shall be searched for
	 * @returns {DOMElement} - the relevant container
	 */
	_getRelevantModelfinderContainer(context_) {
		const carlineContainer = context_.querySelector('.audi-modelfinder__car-lines');

		if (carlineContainer) {
			return carlineContainer;
		}

		return context_.querySelector('.audi-modelfinder__filter-results');
	}

	/**
	 * fade in
	 * @returns {void}
	 */
	_fadeIn() {
		const containerToFadeIn = this._getRelevantModelfinderContainer(this);
		if (containerToFadeIn) {
			containerToFadeIn.classList.remove('audi-modelfinder--faded-out');
		}
	}

	/**
	 * fade out
	 * @returns {void}
	 */
	_fadeOut() {
		const containerToFadeOut = this._getRelevantModelfinderContainer(this);
		if (containerToFadeOut) {
			containerToFadeOut.classList.add('audi-modelfinder--faded-out');
		}
	}
}

if (window.customElements.get('audi-modelfinder') === undefined) {
	customElements.define('audi-modelfinder', ModelFinder);
}
