import CarlinegroupNavigation from './modelfinder-carlinegroup-navigation';
import Viewport from './viewport';

export default class ModelfinderCarlinegroup extends HTMLElement {

	constructor() {
		super();
		this._activateScrolling = this._activateScrolling.bind(this);
	}

	connectedCallback() {
		window.addEventListener('resize', this._activateScrolling);
		this._activateScrolling();
	}

	disconnectedCallback() {
		window.removeEventListener('resize', this._activateScrolling);
	}

	_activateScrolling() {
		if (!Viewport.isDesktop) {
			if (this.navigation) {
				return;
			}

			const carModelsContainer = this.querySelector('.audi-modelfinder__car-models');

			if (carModelsContainer.children.length > 1) {
				this.navigation = new CarlinegroupNavigation(this);
			}
		}
	}
}

if (window.customElements.get('audi-modelfinder-carlinegroup') === undefined) {
	customElements.define('audi-modelfinder-carlinegroup', ModelfinderCarlinegroup);
}
