import {stateRegistry} from 'microkernel';

/**
 * clear the filter
 * @param {state} state_ - previous state
 * @returns {state} - new state
 */
const clearFilter = (state_) => {
	return {
		...state_,
		filter: {}
	};
};

/**
 * change the filter
 * @param {state} state_ - previous state
 * @param {Object} parameterObject_ - parameter object
 * @returns {state} - new state
 */
const changeFilter = (state_, parameterObject_) => {
	const previousFilterValue = state_.filter[parameterObject_.key];
	const nextFilterValue = parameterObject_.value;
	const nextFilter = state_.filter;

	if (nextFilterValue === '' || nextFilterValue === previousFilterValue) {
		// remove filter in case it has no new value or has been toggled
		delete nextFilter[parameterObject_.key];
	}
	else {
		if (nextFilter[parameterObject_.key]) {
			// necessary to move a range filter when changed
			delete nextFilter[parameterObject_.key];
		}
		nextFilter[parameterObject_.key] = nextFilterValue;
	}
	return {
		...state_,
		filter: nextFilter
	};
};

/**
 * change the model selection
 * @param {state} state_ - previous state
 * @param {Object} parameterObject_ - parameter object
 * @returns {state} - new state
 */
const updateModels = (state_, parameterObject_) => {
	return {
		...state_,
		models: parameterObject_
	};
};

class ModelfinderStore {
	static get ACTIONS() {
		return {
			CHANGE_FILTER: 'changeFilter',
			CLEAR_FILTER: 'clearFilter',
			UPDATE_MODELS: 'updateModels'
		};
	}

	/**
	 * initializeStore - fills modelfinder store with preselected filters
	 * @param {Object} initialFilter_ - filter selection
	 * @param {Object} initialModels_ - model selection
	 * @returns {void}
	 */
	static initializeStore(initialFilter_, initialModels_) {
		const payload = {
			filter: initialFilter_,
			models: initialModels_
		};

		ModelfinderStore.addModelfinderStore(payload);
	}

	/**
	 * add a modelfinder store to the stateRegistry
	 * @param {Object} payload_ - the initial storage information
	 * @returns {void}
	 */
	static addModelfinderStore(payload_) {
		stateRegistry.addStore(
			'dbadModelfinderStore',
			payload_,
			{
				changeFilter,
				clearFilter,
				updateModels
			},
			true
		);
	}
}

export {ModelfinderStore, clearFilter as _clearFilter, changeFilter as _changeFilter, updateModels as _updateModels};
