import {stateRegistry} from 'microkernel';
import ModelFinder from '../car-model-finder/modelfinder';
import Viewport from '../modelfinder-carlinegroup/viewport';


export default class ModelFinderFeedbackList {
	constructor(container_) {
		if (!container_) {
			return;
		}

		this._initializeModule(container_);
	}

	/**
	 * _initializeModule
	 * @param {Element} container_ parent container = ModelFinder Filter Custom Element
	 * @returns {void}
	 */
	_initializeModule(container_) {
		if (!container_) {
			return;
		}

		this.parentElement = container_;

		if (this.parentElement) {
			this.feedbackList = this.parentElement.querySelector('.modelfinder-filter-j-feedback-list');
		}

		if (this.feedbackList) {
			this.feedbackListItems = this.feedbackList.querySelectorAll('.modelfinder-filter-j-feedback-item');
			this.feedbackResetButton = this.feedbackList.querySelector('.modelfinder-filter-j-feedback-reset');

			this._bindEventHandler();
			this._addEvents();

			stateRegistry.subscribeToStore('dbadModelfinderStore', this._updateItemOrder);
		}

		this._setActiveFiltersNeeded();
	}

	/**
	 * _bindEventHandler - binds event handlers
	 * @returns {void}
	 */
	_bindEventHandler() {
		this._updateItemOrder = this._updateItemOrder.bind(this);
		this._handleReset = this._handleReset.bind(this);
		this._handleFeedbackItemClick = this._handleFeedbackItemClick.bind(this);
		this._updateFeedbackItemVisibility = this._updateFeedbackItemVisibility.bind(this);
		this._handleResize = this._handleResize.bind(this);
	}

	/**
	 * _addEvents - adds events to feedbacklist elements
	 * @returns {void}
	 */
	_addEvents() {
		[...this.feedbackListItems].forEach(element => element.addEventListener('click', this._handleFeedbackItemClick));

		this.feedbackResetButton.addEventListener('click', () => {
			this._handleReset();
		});

		stateRegistry.subscribeToStore('dbadModelfinderStore', this._updateFeedbackItemVisibility);

		window.addEventListener('resize', this._handleResize);
	}

	/**
	 * _updateItemOrder - sorts the items in the list according to the order in the modelfinder store
	 * @param {Object} state_ state of dbadModelfinderStore
	 * @returns {void}
	 */
	_updateItemOrder(state_) {
		const filter = state_.filter;

		// reset style.order for all disabled items
		[...this.feedbackListItems].forEach(function (element) {
			if (element.dataset.disabled === 'true') {
				element.removeAttribute('style');
			}
		});


		let iterator = 1;

		for (const [key, value] of Object.entries(filter)) {
			let feedbacklistItem = this.feedbackList.querySelector('[data-id="option-' + value + '"]'); // input item

			if (!feedbacklistItem) {
				feedbacklistItem = this.feedbackList.querySelector('[data-id="option-' + key + '"]'); // range item
			}

			if (feedbacklistItem) {
				feedbacklistItem.style.order = iterator;
				iterator++;
			}
		}

		this.feedbackResetButton.style.order = iterator;

	}

	/**
	 * _handleFeedbackItemClick - hide feedback item after click on it and update filter
	 * @param {Event} event_ - click event
	 * @returns {void}
	 */
	_handleFeedbackItemClick(event_) {
		const feedbackItem = event_.currentTarget;
		const filterType = feedbackItem.dataset.filterType;
		const filterKey = ModelFinderFeedbackList.extractOptionName(feedbackItem.dataset.id);

		if (filterType && filterType === 'range') {
			stateRegistry.triggerAction('dbadModelfinderStore', 'changeFilter', {
				key: filterKey,
				value: '',
				type: 'range'
			});
		}
		else {
			const item = this.parentElement.querySelector('[id="' + feedbackItem.dataset.id + '"]');
			const completeFilterKey = ModelFinderFeedbackList.extractOptionName(item.getAttribute('name')) + '-' + filterKey;

			stateRegistry.triggerAction('dbadModelfinderStore', 'changeFilter', {
				key: completeFilterKey,
				value: '',
				type: 'default'
			});
		}
	}

	/**
	 * extract the name of an option
	 * @param {string} rawKey_ - a raw key, with a possible "option" annotation
	 * @returns {string} - a key without a possible "option" annotation
	 */
	static extractOptionName(rawKey_) {
		let key = rawKey_;

		if (key.indexOf('option-') !== -1) {
			key = key.substr(7);
		}

		return key;
	}

	/**
	 * _updateFeedbackItemVisibility - toggles visibility of feedback tags and reset button
	 * @param {state} state_ new state
	 * @returns {void}
	 */
	_updateFeedbackItemVisibility(state_) {
		const activeItemOptions = this._getActiveItemOptions(state_.filter);
		const feedbackBarItems = this.feedbackList.querySelectorAll('.audi-modelfinder__filter-feedback-list-item');

		if (activeItemOptions.length > 0){
			this.feedbackList.parentElement.classList.add('audi-modelfinder__filter-feedback-bar--visible');
		}
		else {
			this.feedbackList.parentElement.classList.remove('audi-modelfinder__filter-feedback-bar--visible');
		}

		[...feedbackBarItems].forEach((feedbackBarItem) => {
			const optionId = feedbackBarItem.dataset.id;

			if (activeItemOptions.indexOf(optionId) !== -1) {
				feedbackBarItem.dataset.disabled = false;

				const rangeOutputItem = this.parentElement.querySelector('output[for="' + optionId + '"]');

				if (rangeOutputItem) {
					const label = feedbackBarItem.querySelector('.modelfinder-filter-j-range-label');
					let newLabel = this.parentElement.querySelector('output[for="' + optionId + '"]').innerText;

					if (newLabel === '') { // safari innerText Bug - innerText of CSS-Hidden Elements has empty String output
						newLabel = this.parentElement.querySelector('output[for="' + optionId + '"]').textContent;
					}

					label.textContent = newLabel;
					feedbackBarItem.dataset.filterType = 'range';
				}
			}
			else {
				feedbackBarItem.dataset.disabled = true;
			}
		});

		this._handleResetButtonVisibility();
	}

	/**
	 * get an array with all currently active items from the filter
	 * @param {Map} filter_ - the filter map
	 * @return {Array} - array of all active items
	 */
	_getActiveItemOptions(filter_) {
		let activeItemOptions = [];

		for (const [rawKey, value] of Object.entries(filter_)) {
			const cleanedFilterKey = ModelFinder.extractFilterKey(rawKey);

			if (cleanedFilterKey !== rawKey) {
				activeItemOptions.push('option-' + value);
			}
			else {
				activeItemOptions.push('option-' + rawKey);
			}
		}

		return activeItemOptions;
	}

	/**
	 * _handleResetButtonVisibility - toggle the state of the reset button
	 * @returns {void}
	 */
	_handleResetButtonVisibility() {
		const activeFilters = this.feedbackList.querySelectorAll('.modelfinder-filter-j-feedback-item[data-disabled="false"]');

		if (activeFilters.length <= this.activeFiltersNeeded) {
			this.feedbackResetButton.dataset.disabled = true;
		}
		else {
			if (this.feedbackResetButton.dataset.disabled === 'true') {
				this.feedbackResetButton.dataset.disabled = false;
			}
		}
	}

	/**
	 *
	 * _handleReset - resets the feedbacklist form and dispatches event for resetting the filter
	 * @returns {void}
	 */
	_handleReset() {
		this.feedbackResetButton.dataset.disabled = true;

		[...this.feedbackListItems].forEach(function(element) {
			element.dataset.disabled = true;
		});

		stateRegistry.triggerAction('dbadModelfinderStore', 'clearFilter');
	}

	/**
	 *
	 * _handleResize - handles what needs to be Done on Resize
	 * @returns {void}
	 */
	_handleResize() {
		this._setActiveFiltersNeeded();
	}

	/**
	 *
	 * _setActiveFiltersNeeded - sets how many filters are needed or the Reset Button to be shown depending on Viewport
	 * @returns {void}
	 */
	_setActiveFiltersNeeded() {
		this.activeFiltersNeeded = (Viewport.isDesktop) ? 1 : 0;
	}
}
