import ModelFinderFeedbackList from './modelfinder-feedbacklist';
import ModelFinder from '../car-model-finder/modelfinder';
import {stateRegistry} from 'microkernel';
import {ModelfinderStore} from '../stores/modelfinder-store';

export default class ModelFinderFilter extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * initialize method
	 * @returns {void}
	 */
	_initializeModule() {
		this.modelfinder = document.querySelector('audi-modelfinder');
		this.toggleButtonFilter = this.querySelector('.modelfinder-filter-j-toggle-button');
		this.filter = this.querySelector('.modelfinder-filter-j-filter');
		this.resultsButton = this.querySelector('.modelfinder-filter-j-button-results');
		this.inputs = this.querySelectorAll('.modelfinder-filter-j-form-element');
		this.filterOffsetControlElement = this.querySelector('.modelfinder-filter-control-pixel');
		this.feedbackList = new ModelFinderFeedbackList(this);

		this._bindEventHandler();
		this._addEvents();

		const initialFilterSelection = this._getSelectedFilters(this.inputs);
		const initialModelSelection = ModelFinder.getModelsData(document.querySelectorAll('.audi-modelfinder__car-model'));

		ModelfinderStore.initializeStore(initialFilterSelection, initialModelSelection);
		this._initializeResultsAmount();

		this._setDefaultValues();
		this._checkForStickyFilter();
	}

	/**
	 * _bindEventHandler - binds event handlers
	 * @returns {void}
	 */
	_bindEventHandler() {
		this._handleResultsAmountChange = this._handleResultsAmountChange.bind(this);
		this._handleFilterChange = this._handleFilterChange.bind(this);
		this._handleFilterStoreUpdate = this._handleFilterStoreUpdate.bind(this);
		this._handleToggleButtonClick = this._handleToggleButtonClick.bind(this);
		this._resetAllSliders = this._resetAllSliders.bind(this);
		this._checkForStickyFilter = this._checkForStickyFilter.bind(this);
		this._handleResize = this._handleResize.bind(this);
	}

	/**
	 * _addEvents - adds events to modelfinder filter elements
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener('modelfinder::content-exchanged', this._handleResultsAmountChange);

		if (this.toggleButtonFilter) {
			this.toggleButtonFilter.addEventListener('click', this._handleToggleButtonClick);
		}

		if (this.resultsButton) {
			this.resultsButton.addEventListener('click', this._handleToggleButtonClick);
		}

		stateRegistry.subscribeToStore('dbadModelfinderStore', this._handleFilterStoreUpdate);

		if (this.inputs) {
			[...this.inputs].forEach((element) => {
				element.addEventListener('change', this._handleFilterChange);
			});
		}

		window.addEventListener('scroll', this._checkForStickyFilter);
		window.addEventListener('resize', this._handleResize);
	}

	/**
	 * summarizes object with selected filters only from all available input fields
	 * @param {Object} filterInputs_ - Nodelist of the available filters
	 * @returns {Object} - Key/Value List of the currenty selected filters
	 */
	_getSelectedFilters(filterInputs_) {
		const initialFilter = {};

		[...filterInputs_].forEach((element) => {
			const input = element.querySelector('input');
			const extractedOptionName = ModelFinderFeedbackList.extractOptionName(input.getAttribute('name'));

			if (input.getAttribute('type') === 'range' && element.dataset.state === 'active') {
				initialFilter[extractedOptionName] = input.value;
			}
			else {
				if (input.checked) {
					const extractedOptionId = ModelFinderFeedbackList.extractOptionName(input.getAttribute('id'));
					const filterKey = extractedOptionName + '-' + extractedOptionId;

					initialFilter[filterKey] = extractedOptionId;
				}
			}
		});
		return initialFilter;
	}

	/**
	 * initialize the amount of results in the mobile button
	 * (retrieved from markup on results template, -1 for initial view else)
	 * @returns {void}
	 */
	_initializeResultsAmount() {
		const resultsAmountSourceElement = this.modelfinder.querySelector('.modelfinder-j-results-amount');
		let amount = -1;

		if (resultsAmountSourceElement && resultsAmountSourceElement.dataset.resultsAmount) {
			amount = parseInt(resultsAmountSourceElement.dataset.resultsAmount, 10);
		}
		this._updateAmountButtonText(amount);
	}

	/**
	 * _handleResultsAmountChange - updates the result amount number inside the mobile results button
	 * @param {CustomEvent} event_ - custom event for content changed
	 * @returns {void}
	 */
	_handleResultsAmountChange(event_) {
		this._updateAmountButtonText(event_.detail.results);
	}

	/**
	 * update the amount of results in the mobile button
	 * @param {number} amount_ - the new amount of results
	 * @returns {void}
	 */
	_updateAmountButtonText(amount_) {
		if (amount_ === -1) {
			this._setAmountButtonText(window.i18n['modelfinder.filter.button.results.initial']);
		}
		else if (amount_ === 0) {
			this._setAmountButtonText(window.i18n['modelfinder.filter.button.results.none']);
		}
		else if (amount_ === 1) {
			this._setAmountButtonText(window.i18n['modelfinder.filter.button.results.single']);
		}
		else {
			this._setAmountButtonText(window.i18n['modelfinder.filter.button.results.multiple'], amount_);
		}
	}

	/**
	 * set the amount and text of the mobile button
	 * @param {string} text_ - the new button text
	 * @param {number} [amount_] - the amount to set
	 * @returns {void}
	 */
	_setAmountButtonText(text_, amount_ = '') {
		const resultButtonTextElement = this.resultsButton.querySelector('.modelfinder-filter-j-button-results-text');
		const resultsAmountTargetElement = this.resultsButton.querySelector('.modelfinder-filter-j-button-results-amount');

		resultsAmountTargetElement.innerText = amount_;
		resultButtonTextElement.innerText = text_;
	}

	/**
	 * _handleToggleButton - toggles State of Button and Filter Container
	 * @returns {void}
	 */
	_handleToggleButtonClick() {
		if (this.filter.dataset.state === 'fold-out') {
			this.toggleButtonFilter.querySelector('.audi-modelfinder__filter-toggle-button-text').textContent = this.toggleButtonFilter.dataset.labelOpen;
			this.filter.dataset.state = 'fold-in';
			document.querySelector('body').classList.remove('modelfinder-filter-js-open');
			this.modelfinder.removeAttribute('style');
			window.scrollTo(0, this.currentBodyScrollPosition);
		}
		else {
			this.currentBodyScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
			this.toggleButtonFilter.querySelector('.audi-modelfinder__filter-toggle-button-text').textContent = this.toggleButtonFilter.dataset.labelClose;
			this.filter.dataset.state = 'fold-out';
			document.querySelector('body').classList.add('modelfinder-filter-js-open');
		}
	}

	/**
	 * _handleFilterChange - checks for target type and calls the related feedback list update function
	 * @param {Event} event_ change Event
	 * @returns {void}
	 */
	_handleFilterChange(event_) {
		if (!event_ || !event_.target || !event_.target.type) {
			return;
		}

		const extractedOptionName = ModelFinderFeedbackList.extractOptionName(event_.target.name);
		const extractedOptionId = ModelFinderFeedbackList.extractOptionName(event_.target.id);

		if (event_.target.type === 'range') {
			stateRegistry.triggerAction('dbadModelfinderStore', 'changeFilter', {
				key: extractedOptionName,
				value: event_.target.value,
				type: 'range'
			});
		}
		else {
			const filterKey = extractedOptionName + '-' + extractedOptionId;
			stateRegistry.triggerAction('dbadModelfinderStore', 'changeFilter', {
				key: filterKey,
				value: extractedOptionId,
				type: 'default'
			});
		}

		/* Reset scroll position of page when filtered */
		this.currentBodyScrollPosition = 0;
	}

	/**
	 * handle an update to the filter store
	 * @param {Object} state_ state of dbadModelfinderStore
	 * @returns {void}
	 */
	_handleFilterStoreUpdate(state_) {
		this._resetForm();

		for (const [key, value] of Object.entries(state_.filter)) {
			const cleanedFilterKey = String(ModelFinder.extractFilterKey(key));

			if (cleanedFilterKey !== key) {
				const filterItem = this.querySelector('[id="option-' + value + '"]');
				filterItem.checked = true;
			}
			else {
				const rangeItem = this.querySelector('[name="option-' + key + '"]');

				rangeItem.value = value;
				this._triggerChangeEventForGivenElement(rangeItem);
			}
		}
	}

	/**
	 * _triggerEventForGivenElement - trigger event for given element
	 * @param  {Element} element_ {description}
	 * @fires {Event} triggeredChangeEvent - fire change Event to start connected handler
	 * @returns {void}
	 */
	_triggerChangeEventForGivenElement(element_) {
		const triggeredChangeEvent = document.createEvent('Event');

		triggeredChangeEvent.initEvent('change', false, true);
		element_.dispatchEvent(triggeredChangeEvent);
	}

	/**
	 * _resetForm - calls reset functions for checkboxes and sliders
	 * @returns {void}
	 */
	_resetForm() {
		this._resetAllCheckboxes();
		this._resetAllSliders();
	}

	/**
	 * _resetAllCheckboxes - deselects all the checkboxes
	 * @returns {void}
	 */
	_resetAllCheckboxes() {
		const checkboxes = this.querySelectorAll('.audi-modelfinder__filter-checkbox .modelfinder-filter-j-form-element input');

		[...checkboxes].forEach(function(checkbox) {
			checkbox.checked = false;
		});
	}

	/**
	 * _resetAllSliders - resets all slider to max value and sets them back to inactive
	 * @returns {void}
	 */
	_resetAllSliders() {
		const sliders = this.querySelectorAll('.audi-modelfinder__filter-range .modelfinder-filter-j-form-element');

		[...sliders].forEach((slider) => {
			const sliderInput = slider.querySelector('input');

			sliderInput.value = sliderInput.max;
			this._triggerChangeEventForGivenElement(sliderInput);

			slider.dataset.state = 'inactive';
		});
	}

	/**
	 * _checkForStickyFilter - checks if filter should be Sticky depending on how far the Body is scrolled.
	 * @returns {void}
	*/
	_checkForStickyFilter() {
		let currentXScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
		let height = this.feedbackList.feedbackList.getBoundingClientRect().height + this.toggleButtonFilter.getBoundingClientRect().height;

		if (currentXScrollPosition > this.filterOffset) {
			this.classList.add('audi-modelfinder-filter--sticky');
			this.modelfinder.style.paddingTop = height + this.defaultPadding + 'px';
		}
		else {
			this.classList.remove('audi-modelfinder-filter--sticky');
			this.modelfinder.style.paddingTop = this.defaultPadding + 'px';
		}
	}

	/**
	 * _handleResize - handles Resize Event
	 * @returns {void}
	*/
	_handleResize (){
		this._setDefaultValues();
	}

	/**
	 * _setDefaultValues - sets Default Values
	 * @returns {void}
	*/
	_setDefaultValues() {
		this.modelfinder.removeAttribute('style');
		this.filterOffset = this.filterOffsetControlElement ? Math.abs(this.filterOffsetControlElement.offsetTop) : 0;
		this.defaultPadding = this.modelfinder ? parseInt(getComputedStyle(this.modelfinder).paddingTop, 10) : 0;
	}
}
if (window.customElements.get('audi-modelfinder-filter') === undefined) {
	customElements.define('audi-modelfinder-filter', ModelFinderFilter);
}
